import React from "react";

const Main: React.FC = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "xxx-large",
        textTransform: "uppercase",
      }}
    >
      <p>THE GLOBAL CHOICE FOR JERSEY CATTLE</p>
    </div>
  );
};

export default Main;
