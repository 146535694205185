import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import Logo from "./Logo.tsx";

const Header: React.FC = () => {
  return (
    <Navbar bg="light" data-bs-theme="light">
      <Nav className="me-auto">
        <Logo />
        <Nav.Link href="#">HOME</Nav.Link>
        <Nav.Link href="#">WHY THE JERSEY?</Nav.Link>
        <Nav.Link href="#">PURPOSE</Nav.Link>
        <Nav.Link href="#">CONTACT</Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default Header;
