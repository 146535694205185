import React from "react";
import { Carousel } from "react-bootstrap";

interface IItem {
  title: string;
  content: string;
}

const items: IItem[] = [
  {
    title: "JERSEY MILK & PRODUCE",
    content:
      "You might already agree with us when we say our Pedigree Jersey Cow is something rather special. But why not find out more about what makes our Jersey cows and their produce so special? Discover why pedigree makes all the difference.",
  },
  {
    title: "JERSEY PRODUCERS DIRECTORY",
    content:
      "Our members love their cows and are proud of the milk their cows produce each and every day for you. From the nutritious milk which you pour over your cereal or into your tea or coffee each day, really beautiful high quality tasting produce including cream, clotted cream, yogurt, cheese and ice cream. Take a browse in our producer directory, meet our members and try their quality range of produce.",
  },
  {
    title: "JERSEY PEDIGREE REGISTRATION",
    content:
      "Pedigree matters. A cow's pedigree tells you so much more than just the parentage, for the member it enable them to make future breeding decisions, for the consumer it provides provenance and a standard of quality and for industry it provides traceability.",
  },
  {
    title: "JCS MEMBERSHIP",
    content:
      "Our JCS members enjoy additional exclusive content, including documentation, publications, pedigree registrations, milk recording, type classification services and other management tools. Join today and become part of the fastest growing pedigree dairy breed of the future.",
  },
];

const AboutJerseyCarousel: React.FC = () => {
  return (
    <Carousel
      controls
      interval={null}
      data-bs-theme="dark"
      style={{ maxWidth: "50%" }}
    >
      {items.map((item) => (
        <Carousel.Item style={{ padding: "0 10%" }}>
          <h3>{item.title}</h3>
          <p>{item.content} </p>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default AboutJerseyCarousel;
