import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

interface IPurposeItem {
  id: number;
  title: string;
  content: string;
}

const items: IPurposeItem[] = [
  {
    id: 1,
    title: "MISSION",
    content:
      "Our mission is to connect Danish Jersey cattle breeders with international buyers, facilitating the trade of premium Jersey cattle and contributing to the global growth and recognition of the Jersey breed.",
  },
  {
    id: 2,
    title: "VISION",
    content:
      "Our vision is to be the premier global platform for the trade of high-quality Jersey cattle from Denmark, revolutionizing the industry through excellence, transparency, and customer satisfaction.",
  },
];

const Purpose: React.FC = () => {
  return (
    <div style={{ margin: "50px 0" }}>
      <h2
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "40px",
          margin: "50px 0",
        }}
      >
        PURPOSE
      </h2>
      <Row xs={1} md={2} className="g-4">
        {items.map((item) => (
          <Col key={item.id}>
            <Card style={{ border: "none" }}>
              <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <Card.Text>{item.content}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Purpose;
