import { Card, Button } from "react-bootstrap";

const AboutJerseyCard: React.FC = () => {
  return (
    <Card style={{ width: "50%", border: "none" }}>
      <Card.Body>
        <Card.Title>WHY THE JERSEY?</Card.Title>
        <Card.Text>
          There are several advantages associated with Danish Jerseys compared
          to other dairy breeds.
        </Card.Text>
        <Button variant="primary">
          <Card.Link
            href="#"
            style={{ color: "white", textDecoration: "none" }}
          >
            READ MORE
          </Card.Link>
        </Button>
      </Card.Body>
    </Card>
  );
};

export default AboutJerseyCard;
