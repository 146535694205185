import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const EmailFrom: React.FC = () => {
  return (
    <Form>
      <Form.Group>
        <Form.Label>Name: *</Form.Label>
        <Form.Control required type="text" placeholder="Normal text" />
        <br />
      </Form.Group>
      <Form.Group
        className="mb-3"
        controlId="exampleForm.ControlInput1"
        aria-required
      >
        <Form.Label>Email address: *</Form.Label>
        <Form.Control required type="email" placeholder="name@example.com" />
      </Form.Group>
      <Form.Group
        className="mb-3"
        controlId="exampleForm.ControlTextarea1"
        aria-required
      >
        <Form.Label>Message: *</Form.Label>
        <Form.Control required as="textarea" rows={3} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default EmailFrom;
