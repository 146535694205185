import React from "react";
import ReactDOM from "react-dom/client";
import Header from "./components/Header/Header.tsx";
import { Container } from "react-bootstrap";
import Main from "./components/Main/Main.tsx";
import About from "./components/About/About.tsx";
import Purpose from "./components/Purpose/Purpose.tsx";
import EmailFrom from "./components/EmailForm/EmailForm.tsx";
import Footer from "./components/Footer/Footer.tsx";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Container>
      <Header />
      <Main />
      <About />
      <Purpose />
      <EmailFrom />
      <Footer />
    </Container>
  </React.StrictMode>
);
