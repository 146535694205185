import AboutJerseyCard from "./AboutJerseyCard.tsx";
import AboutJerseyCarousel from "./AboutJerseyCarousel.tsx";

const About = () => {
  return (
    <div style={{ height: "100vh", display: "flex", gap: "10px" }}>
      <AboutJerseyCard />
      <AboutJerseyCarousel />
    </div>
  );
};

export default About;
